@font-face {
font-family: '__AkkuratLL_87549e';
src: url(/b/a/ecom-website/9cd682b77c3d56d5db223e1b5a6bd887020081dc/_next/static/media/26a8fb288ae942e8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__AkkuratLL_87549e';
src: url(/b/a/ecom-website/9cd682b77c3d56d5db223e1b5a6bd887020081dc/_next/static/media/52aded988c1a1caf-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: light;
}@font-face {font-family: '__AkkuratLL_Fallback_87549e';src: local("Arial");ascent-override: 91.39%;descent-override: 22.87%;line-gap-override: 0.00%;size-adjust: 105.04%
}.__className_87549e {font-family: '__AkkuratLL_87549e', '__AkkuratLL_Fallback_87549e'
}.__variable_87549e {--font-sans: '__AkkuratLL_87549e', '__AkkuratLL_Fallback_87549e'
}

@font-face {
font-family: '__EditorialNew_482953';
src: url(/b/a/ecom-website/9cd682b77c3d56d5db223e1b5a6bd887020081dc/_next/static/media/2f495048b025fb3f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__EditorialNew_482953';
src: url(/b/a/ecom-website/9cd682b77c3d56d5db223e1b5a6bd887020081dc/_next/static/media/d6bb81b4bc659df4-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: italic;
}@font-face {font-family: '__EditorialNew_Fallback_482953';src: local("Arial");ascent-override: 106.99%;descent-override: 36.47%;line-gap-override: 12.16%;size-adjust: 82.25%
}.__className_482953 {font-family: '__EditorialNew_482953', '__EditorialNew_Fallback_482953'
}.__variable_482953 {--font-serif: '__EditorialNew_482953', '__EditorialNew_Fallback_482953'
}

